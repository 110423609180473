<template>
  <div>
    <ActionList
      :filters="filters"
      :actions="actions"
      :load-items-callback="loadItemsCallback"
      :fixed-filters="fixedFilters"
      db-table="items"
      :empty-text="$t('noItemsFound')"
      :call-to-actions="callToActions"
      :import-data-values="importDataValues"
    >
      <template v-slot:item="{ item, index }">
        <ItemListItem
          :item="item"
          :key="index"
          @selectItem="$emit('selectItem', item)"
        />
        <v-divider inset />
      </template>
    </ActionList>
  </div>
</template>

<script>
  export default {
    name: 'ItemList',
    props: {
      loadItemsCallback: {
        type: Function,
        default: () => null
      },
      callToActions: {
        type: Array,
        default: () => []
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      actions: {
        type: Array,
        default: () => []
      }
    },
    components: {
      'ItemListItem': () => import('@/components/ItemListItem'),
      'ActionList': () => import('@/components/common/list/ActionList')
    },
    data: () => ({
    }),
    computed: {
			importDataValues() {
        return null
				// return {
				// 	createCallback: this.createItem,
				// 	updateCallback: null,
				// 	defaultCreateObject: {

				// 	},
				// 	schema: [
				// 		{
				// 			property: 'title',
				// 			title: 'Title',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'type',
				// 			title: 'Type',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'sku',
				// 			title: 'Sku',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'description',
				// 			title: 'Description',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'price.amount',
				// 			title: 'Amount',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'price.currency_code',
				// 			title: 'Currency',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'price.data.include_tax',
				// 			title: 'Tax Rate',
				// 			required: true,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'image',
				// 			title: 'Image',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'barcode',
				// 			title: 'Barcode',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'minimumOrderQuantity',
				// 			title: 'Minimum Order Quantity',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'maximumOrderQuantity',
				// 			title: 'Maximum Order Quantity',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'sellOnline',
				// 			title: 'Sell Online',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'sellInStore',
				// 			title: 'Sell InStore',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		},
				// 		{
				// 			property: 'sellInEtags',
				// 			title: 'Sell InEtags',
				// 			required: false,
				// 			type: 'String',
				// 			defaultValue: null,
				// 			value: null
				// 		}
				// 	]
				// }
			},
      filters() {
        return [
          {
            key: 'categories.uuid',
            label: this.$t('category'),
            type: 'select',
            value: null,
            items: this.categoryOptions,
            operator: 'contains'
          },
          {
            key: 'title',
            label: this.$t('title'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'price.amount',
            label: this.$t('price'),
            type: 'number',
            value: null,
            operator: 'equals'
          },
          {
            key: 'sale_price.amount',
            label: this.$t('salePrice'),
            type: 'number',
            value: null,
            operator: 'equals'
          },
          {
            key: 'sku',
            label: this.$t('sku'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'type',
            label: this.$t('itemType'),
            type: 'select',
            value: null,
            items: this.itemTypes,
            operator: 'contains'
          },
          {
            key: 'uuid',
            label: this.$t('universalUniqueIdentifier'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'mainCategory',
            label: this.$t('mainCategory'),
            type: 'select',
            value: undefined,
            items: this.mainCategoryOptions,
            operator: 'equals'
          }
        ]
      },
      slicedItems() {

        if(this.filteredItems !== null && this.filteredItems !== undefined) {
          return this.filteredItems.slice(this.startIndex, this.length)
        }

        return this.items.slice(0, this.length)
      },
      categoryOptions() {
        return this.$store.state.categories.filter(notNull => notNull).map(category => {
          return {
            value: category.uuid,
            text: category.name
          }
        })
      },
      mainCategoryOptions() {
        return [
          {
            value: null,
            text: "Without Main Category"
          }
        ]
      },
      itemTypes() {
        return this.$store.state.itemTypes.filter(notNull => notNull).map(type => {
          return {
            text: type.title,
            value: type.namespace
          }
        })
      }
    },
    methods: {
      sellOnlinePreview(item) {
        this.itemToSellOnline = item
      },
			createItem() {

			}
    }
  }
</script>
